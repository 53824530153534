import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {

  const today = new Date();

  return (
    <div className='footer'>
        <p className='footerContent'>© {today.getFullYear()} KANI All rights reserved.</p>
        <Link className='imprint' to="/imprint">Imprint</Link>
        <ul className='iconList'>
            <li>
              <a href="https://www.instagram.com/kanipaperstraw/">
                <img className='insta' src={process.env.PUBLIC_URL + `/images/instagram.webp`}></img>
              </a>
            </li>
        </ul>
    </div>
  );
};

export default Footer;