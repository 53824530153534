import React from "react";
import { Link } from 'react-router-dom';

const NotFound = () => {
  
  return (
    <div className='notFound'>
        <h1>404 PAGE NOT FOUND</h1>
        <p>Seems like the page you search don't exist.</p>
        <Link className='notFoundButton' to="/">Go Back To Home</Link>
    </div>
  );
};
  
export default NotFound;