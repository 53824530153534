import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";

const Product = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
        threshold: 0.3,
      });
    
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: -180 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -180 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.6,
          staggerChildren: 0.5,
        }}
        className='product'>
        <div className='one-column'>
          <motion.h2
           initial={{ opacity: 0, y: -180 }}
           animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -180 }}
           transition={{
             ease: "easeInOut",
             duration: 1,
             delay: 0.6,
           }} 
          className="headline"
          >HOW WE CREATE PRODUCTS</motion.h2>
          <motion.p
           initial={{ opacity: 0, y: +180 }}
           animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : +180 }}
           transition={{
             ease: "easeInOut",
             duration: 1,
             delay: 0.6,
           }} 
          className="text"
          >At KANI, we know the power of creativity and its ability to inspire life. That's why we work with a professional team of designers and engineers to create innovative products based on our mission to contribute to a more sustainable world. Join us on our journey, into the new age of renewal and unity. At KANI, we follow one simple principle every day: <span className='bold'>There is magic in every beginning.</span></motion.p>
          <img className='productPhoto' src={process.env.PUBLIC_URL + `/images/products.webp`}></img>
        </div> 
      </motion.div>
    );
  };
  
  export default Product;

