import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";

const Final = () => {
  const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: '-100px 0px',
      threshold: 0.3,
    });
  
  return (
      <motion.div
        ref={ref}
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#60f",
          display: "flex",
          placeItems: "center",
          placeContent: "center"
        }}
        initial={{ backgroundColor: "#000" }}
        animate={{ backgroundColor: inView ? "#fffffd":"#000" }}
        transition={{
          duration: 2,
          staggerChildren: 0.5
        }}
        className='final'>
        <motion.p
          ref={ref}
          initial={{ color: "#fffffd" }}
          animate={{ color: inView ? "#000":"#fffffd" }}
          transition={{
            duration: 2,
          }}
          className='finalTextUp'>
          Let's contribute to our Mission cause in the end
        </motion.p>
        <motion.p
          ref={ref}
          initial={{ color: "#fffffd" }}
          animate={{ color: inView ? "#000":"#fffffd" }}
          transition={{
            duration: 2,
          }}
          className='finalTextDown'>
          IT'S YOUR CHOICE
        </motion.p>
        <a href='mailto:contact@kani.green'>
        <div className='btn'>
          <span>Contact Us</span>
        </div>
        </a>
      </motion.div>
  );
};
  
export default Final;