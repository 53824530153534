import React from "react";
import { useInView } from 'react-intersection-observer';
import { motion } from "framer-motion";

const Material = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
        threshold: 0.2,
      });
    
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: -180 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -180 }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          delay: 0.6,
        }}
        className='grid'>
        <div className='one-column'>
          <h2 className="headline">BAMBOO</h2>
          <p className="text">Bamboo represents purity, clarity and growth in Asian culture and is an elastic but strong raw material. It enables us to create a wide range of handmade products, which convince with their unique surface and appealing design.</p>
          <motion.img
            initial={{ y: 0 }}
            animate={{ y: +10 }}
            transition={{ 
              duration: 2.5,
              yoyo: Infinity
            }}
            className='bamboo' 
            src={process.env.PUBLIC_URL + `/images/bamboo.webp`}
          ></motion.img>
        </div> 
        <div className='two-column'>
          <motion.div
          initial={{ opacity: 0, x: -180 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -180 }}
          transition={{
            ease: "easeInOut",
            duration: 1.5,
            delay: 0.6,
          }} 
          className="column-left"
          >
            <h2 className="headline">WOOD</h2>
            <p className="text">Wood is another natural raw material that allows us to create a variety of high quality products due to its numerous processing capabilities as well as its unique natural surfaces. Like KANI, wood stands for renewal and growth.</p>
            <motion.img
            initial={{ y: 0 }}
            animate={{ y: +10 }}
            transition={{ 
              duration: 2.5,
              yoyo: Infinity
            }} 
            className='wood' 
            src={process.env.PUBLIC_URL + `/images/wood.webp`}
            ></motion.img>
          </motion.div>
          <motion.div
          initial={{ opacity: 0, x: +180 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : +180 }}
          transition={{
            ease: "easeInOut",
            duration: 1.5,
            delay: 0.6,
          }} 
          className="column-right"
          >
            <h2 className="headline">GRASS</h2>
            <p className="text">Lepironia grass is one of our most sustainable raw materials. It grows in the alkaline water in the Mekong Delta region, which is found in this special form only in Vietnam.</p>
            <motion.img
            initial={{ y: 0 }}
            animate={{ y: +10 }}
            transition={{ 
              duration: 2.5,
              yoyo: Infinity
            }} 
            className='grass' 
            src={process.env.PUBLIC_URL + `/images/grass.webp`}
            ></motion.img>
          </motion.div>
        </div>
      </motion.div>
    );
  };
  
  export default Material;

