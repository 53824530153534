import { useEffect, useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';

//scss style component
import "./sass/main.scss";

// Components
import Header from "./components/Header";
import Banner from "./components/Banner";
import Loader from "./components/Loader";
import Material from "./components/Material";
import Product from "./components/Product";
import Final from "./components/Final";
import Footer from "./components/Footer";
import Imprint from "./components/Imprint";
import NotFound from "./components/Error";

//initialize Google Analytics
ReactGA.initialize('UA-203687221-1');

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
      ReactGA.pageview('/');
  }, [loading]);
 
  return (
    <AnimateSharedLayout type='crossfade'>
      <AnimatePresence>
        {loading ? (
          <motion.div key='loader'>
            <Loader setLoading={setLoading} />
          </motion.div>
        ) : (
          <>
            <Router>
              <Header />
              <Switch>
                <Route exact path="/">
                  <Banner />
                  {!loading && (
                    <div className='transition-image final'>
                      <motion.img
                        transition={{ ease: [0.6, 0.01, -0.05, 0.9], duration: 1.6 }}
                        src={process.env.PUBLIC_URL + `/images/image-2.webp`}
                        layoutId='main-image-1'
                      />
                    </div>
                  )}
                  <Material />
                  <Product />
                  <Final />
                </Route>
                <Route exact path="/imprint">
                  <Imprint />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            <Footer />
            </Router>
          </>
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
}

export default App;