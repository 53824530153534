import React from "react";
import { Link } from 'react-router-dom';

const Imprint = () => {
  
  return (
    <div className='imprintText'>
        <Link className='imprintButton' to="/">Go Back To Home</Link>
        <h1>Imprint <br></br></h1>
        Information according to § 5 TMG <br></br>
        KANI IMPORT EXPORT TRADING COMPANY LIMITED <br></br>
        15 Bùi Thị Xuân <br></br>
        Phường Bến Thành <br></br>
        700000 Ho Chi Minh <br></br>
        Represented by: <br></br>
        TRẦN THỊ TRÀ GIANG <br></br>
        Chairman of the supervisory board:<br></br>
        TRẦN THỊ TRÀ GIANG<br></br>
        Contact<br></br>
        E-mail: contact@kani.green <br></br>
        Sales tax ID <br></br>
        Sales tax identification number according to § 27 a sales tax law: 0316283925 <br></br>

        Consumer dispute resolution/universal arbitration board
        We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.<br></br>

        Source:
        https://www.e-recht24.de
    </div>
  );
};
  
export default Imprint;